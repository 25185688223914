/** @jsx jsx */


import OrtYaml from '../data/orte.yaml'
import AusstellungYaml from '../data/kuenstler.yaml'
import KategorienYaml from '../data/kunstkat.yaml'
import Layout from '../components/layout'
import MySEO from '../components/seo'
import {Flex,Box,Text,jsx} from 'theme-ui'
import SelectBox from '../components/SelectBox'
import {Link} from 'gatsby'


const Kulturschaffende = () => {
    
    const orte = OrtYaml.content
    const kategorien = KategorienYaml.content
    const thema= AusstellungYaml.content
  
   
    if(orte&&kategorien&&thema)
    return(
        <Layout>
             <MySEO title="Künstler*innen im Oberen Mittelrheintal" description="Liste von Künstler*innen im Oberen Mittelrheintal" />
          <Flex sx={{width:"100%",justifyContent:"flex-end",alignItems:'flex-end'}}>
            <Box sx={{p:2,textAlign:'right'}}>
              <Text sx={{color:'red !important',fontSize:[4,5]}}>&rarr; Mitmachen?</Text>
              <Link sx={{pr:1,color:'text'}}to="/artists/sogehts">So geht´s</Link>
            </Box>
          </Flex>
           <div>
 
           <SelectBox kategorien={kategorien} orte={orte} thema={thema} titel="Kulturschaffende" />
         </div> 
        
      
        </Layout>
    )
    return null
}
        
export default Kulturschaffende